import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

import { API_URL } from "../../../config";

const ProductsTable = ({ category_id, products }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Stock</th>
                    <th>Price</th>
                    <th>Visible</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, index) => (
                    <tr>
                        <td>{product.name}</td>
                        <td>{product.stock}</td>
                        <td>${product.price.toFixed(2)}</td>
                        <td className="capitalize">{product.visible.toString()}</td>
                        <td>{new Date(product.created_at).toLocaleString()}</td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/products/categories/${category_id}/${product.product_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const CreateProduct = ({ category_id, setRefresh }) => {
    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState(1);
    const [productDeliveryType, setProductDeliveryType] = useState("automatic");
    const [productMinQuantity, setProductMinQuantity] = useState(1);
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/products`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    category_id: category_id,
                    name: productName,
                    price: parseFloat(productPrice),
                    delivery_type: productDeliveryType,
                    min_quantity: productMinQuantity
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setProductName("");
            setProductPrice(0);
            setProductDeliveryType("automatic");
            setProductMinQuantity(0);
            setRefresh(Math.random());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box mt-2">
            <DashboardLoading fullPage={false} />
        </div>
    );

    return (
        <div className="item-create-box mt-2">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Create product</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Name</label>
                <input type="text" className="input" placeholder="My Product Name" defaultValue={productName} onChange={(e) => setProductName(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Price</label>
                <input type="text" className="input" min={0} defaultValue={productPrice} onChange={(e) => setProductPrice(parseFloat(e.target.value).toFixed(2))} />
            </div>

            <div className="input-group">
                <label>Delivery Type</label>
                <select className="input" defaultValue={productDeliveryType} onChange={(e) => setProductDeliveryType(e.target.value)}>
                    <option value="automatic">Automatic</option>
                    <option value="manual">Manual</option>
                </select>
            </div>

            <div className="input-group">
                <label>Minimum Quantity</label>
                <input type="number" className="input" placeholder="1" min={1} defaultValue={productMinQuantity} onChange={(e) => setProductMinQuantity(parseInt(e.target.value))} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Create Product</button>
        </div>
    )
};

const EditProductCategory = ({ category_id, setRefresh, tags, possiblePlacements, categoryName, setCategoryName, categoryDescription, setCategoryDescription, categoryImageURL, setCategoryImageURL, categoryTags, setCategoryTags, categoryPlacement, setCategoryPlacement, categoryVisible, setCategoryVisible }) => {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/productcategories/${category_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: categoryName,
                    description: categoryDescription,
                    image_url: categoryImageURL,
                    tags: categoryTags,
                    visible: categoryVisible,
                    placement: categoryPlacement
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
            };
            setRefresh(Math.random().toString());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        }
    };

    const submitDelete = async () => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/productcategories/${category_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
            };
            setLoaded(true);
            navigate("/dashboard/products/categories");
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    if(loaded === false) return (
        <div className="item-create-box mt-2">
            <DashboardLoading fullPage={false} />
        </div>
    );

    return (
        <div className="item-create-box mt-2">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Edit product category</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Name</label>
                <input type="text" className="input" placeholder="My Category Name" defaultValue={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Description</label>
                <textarea rows={3} className="input" placeholder="Veniam sunt aliquip sint consectetur ut duis do ea aute. Enim aliquip commodo laboris anim nisi aute laborum." defaultValue={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Image URL</label>
                <input type="text" className="input" placeholder="https://i.imgur.com/xyz.png" defaultValue={categoryImageURL} onChange={(e) => setCategoryImageURL(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Tags</label>
                <Select
                    className="input input-custom"
                    isMulti={true}
                    defaultValue={categoryTags.map(t => { return { value: t, label: tags.findIndex(tg => tg.tag_id === t) !== -1 ? tags[tags.findIndex(tg => tg.tag_id === t)].name : "Deleted Tag" } })}
                    onChange={(value) => setCategoryTags(value.map(t => t.value))}
                    options={tags.map(t => { return { value: t.tag_id, label: t.name } })}
                />
            </div>

            <div className="input-group">
                <label>On-Site Placement</label>
                <select className="input" defaultValue={categoryPlacement} onChange={(e) => setCategoryPlacement(e.target.value)}>
                    {Array(possiblePlacements).fill(1).map((placement, index) => {
                        return <option value={index} key={`product-category-placement-${placement}-${index}`}>Placement #{index + 1}</option>
                    })}
                </select>
            </div>

            <div className="input-group input-group-checkbox">
                <label>Visible</label>
                <input type="checkbox" defaultChecked={categoryVisible} onChange={(e) => setCategoryVisible(e.target.checked)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Save</button>
            <button className="btn btn-dng" onClick={() => submitDelete()}>Delete Category</button>
        </div>
    )
};

const ProductCategory = () => {
    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [categoryImageURL, setCategoryImageURL] = useState("");
    const [categoryTags, setCategoryTags] = useState("");
    const [categoryPlacement, setCategoryPlacement] = useState(null);
    const [categoryVisible, setCategoryVisible] = useState(true);
    const [possiblePlacements, setPossiblePlacements] = useState([]);
    const [selectedView, setSelectedView] = useState("edit_product");
    const [products, setProducts] = useState([]);
    const [canCreateProduct, setCanCreateProduct] = useState(false);
    const [tags, setTags] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const getCategoryId = () => {
        try {
            let cId = window.location.pathname.split("/")[4];
            if(cId.includes("/")) return cId.split("/")[0];
            return cId;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/productcategories/${getCategoryId()}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setCategoryName(data.product_category.name);
            setCategoryDescription(data.product_category.description);
            setCategoryImageURL(data.product_category.image_url);
            setCategoryTags(data.product_category.tags);
            setCategoryVisible(data.product_category.visible);
            setCategoryPlacement(data.product_category.placement);
            setProducts(data.product_category.products);
            setPossiblePlacements(data.possible_placements);
            setCanCreateProduct(data.can_create_product);
            setTags(data.tags);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(refresh);
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">{categoryName}</div>
                    <div className="user-home-welcome-text">Create, view and manage products in the {categoryName} category</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <ProductsTable category_id={getCategoryId()} products={products} />
                    </div>
                    {canCreateProduct ? <div className="dashboard-page-split-right">
                        <div className="dashboard-page-view-select">
                            <button className={`btn btn-view-sel ${selectedView === "edit_product" ? "btn-view-sel-active" : ""}`} onClick={() => setSelectedView("edit_product")}>Edit Category</button>
                            <button className={`btn btn-view-sel ${selectedView === "create_product" ? "btn-view-sel-active" : ""}`} onClick={() => setSelectedView("create_product")}>Create Product</button>
                        </div>
                        {selectedView === "edit_product"
                        ? <EditProductCategory
                            category_id={getCategoryId()}
                            setRefresh={setRefresh}
                            tags={tags}
                            possiblePlacements={possiblePlacements}
                            categoryName={categoryName}
                            setCategoryName={setCategoryName}
                            categoryDescription={categoryDescription}
                            setCategoryDescription={setCategoryDescription}
                            categoryImageURL={categoryImageURL}
                            setCategoryImageURL={setCategoryImageURL}
                            categoryTags={categoryTags}
                            setCategoryTags={setCategoryTags}
                            categoryPlacement={categoryPlacement}
                            setCategoryPlacement={setCategoryPlacement}
                            categoryVisible={categoryVisible}
                            setCategoryVisible={setCategoryVisible} 
                        />
                        : <CreateProduct category_id={getCategoryId()} setRefresh={setRefresh} />}
                    </div> : null}
                </div>
            </div>
        </div>
    )
};

export default ProductCategory;