import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const AnnouncementsTable = ({ setRefresh, announcements }) => {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const deleteAnnouncement = async(announcement_id) => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/announcements/${announcement_id}`, {
                method: "DELETE",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setRefresh(Math.random());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Content</th>
                    <th>Posted By</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {announcements.map((announcement, index) => (
                    <tr key={`${announcement.announcement_id}-${index}`}>
                        <td>{announcement.announcement_id}</td>
                        <td>{announcement.content}</td>
                        <td>{announcement.posted_by}</td>
                        <td>{new Date(announcement.created_at).toLocaleString()}</td>
                        <td><button className="btn btn-snb btn-dng" onClick={() => deleteAnnouncement(announcement.announcement_id)}>Delete</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const CreateAnnouncement = ({ setRefresh }) => {
    const [content, setContent] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/announcements`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    content
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setContent("");
            setRefresh(Math.random().toString());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Post new announcement</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Content</label>
                <textarea rows={4} className="input" placeholder={"Sunt exercitation in aliqua velit deserunt ea. Reprehenderit occaecat id fugiat quis incididunt officia eiusmod do ipsum fugiat duis sit id eu."} defaultValue={content} onChange={(e) => setContent(e.target.value)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Post announcement</button>
        </div>
    )
};

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/announcements`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setAnnouncements(data.announcements);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Announcements</div>
                    <div className="user-home-welcome-text">Create, view and manage announcements</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <AnnouncementsTable setRefresh={setRefresh} announcements={announcements} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <CreateAnnouncement setRefresh={() => setRefresh()} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Announcements;