import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const AuditLogTable = ({ events }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Event Type</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {events.map((event, index) => (
                    <tr>
                        <td>{event.type}</td>
                        <td>{event.description}</td>
                        <td>{new Date(event.created_at).toLocaleString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const EditAdminUser = ({ product_categories, user_id, username, user_type, accessible_category_ids }) => {
    const [adminUsername, setAdminUsername] = useState(username);
    const [adminUsertype, setAdminUsertype] = useState(user_type);
    const [accessibleCategories, setAccessibleCategories] = useState(accessible_category_ids);
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/adminusers/${user_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: adminUsername,
                    user_type: adminUsertype,
                    accessible_category_ids: accessibleCategories
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };

            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        };
    };

    const submitDelete = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/adminusers/${user_id}`, {
                method: "DELETE",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };

            navigate("/dashboard/adminusers");
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        };
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Edit admin user</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Username</label>
                <input type="text" className="input" placeholder="myadminuser" defaultValue={adminUsername} onChange={(e) => setAdminUsername(e.target.value)} />
            </div>

            <div className="input-group">
                <label>User Type</label>
                <select className="input" defaultValue={adminUsertype} onChange={(e) => setAdminUsertype(e.target.value)}>
                    <option value="support">Support</option>
                    <option value="stocker">Stocker</option>
                    <option value="supportstocker">Support & Stocker</option>
                    <option value="superuser">Superuser</option>
                </select>
            </div>

            {["stocker", "supportstocker"].includes(adminUsertype)
            ?   <div className="input-group">
                    <label>Accessible Category IDs</label>
                    <Select
                        className="input input-custom"
                        isMulti={true}
                        defaultValue={accessibleCategories.map(t => { return { value: t, label: product_categories.findIndex(tg => tg.category_id === t) !== -1 ? product_categories[product_categories.findIndex(tg => tg.category_id === t)].name : "Deleted Category" } })}
                        onChange={(value) => setAccessibleCategories(value.map(t => t.value))}
                        options={product_categories.map(t => { return { value: t.category_id, label: t.name } })}
                    />
                </div> 
            : null}

            <button className="btn btn-snb" onClick={() => submit()}>Edit admin user</button>
            <button className="btn btn-dng" onClick={() => submitDelete()}>Delete admin user</button>
        </div>
    )
};

const AdminUser = () => {
    const [username, setUsername] = useState(null);
    const [adminUserType, setAdminUserType] = useState(null);
    const [auditEvents, setAuditEvents] = useState([]);
    const [accessibleCategories, setAccessibleCategories] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [lastLoginAt, setLastLoginAt] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const getUserId = () => {
        let oId = window.location.pathname.split("/")[3];
        return oId.includes("/") ? oId.split("/")[0] : oId;
    };

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/adminusers/${getUserId()}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setUsername(data.admin_user.username);
            setAdminUserType(data.admin_user.user_type);
            setAccessibleCategories(data.admin_user.accessible_category_ids);
            setLastLoginAt(data.admin_user.last_login_at);
            setCreatedAt(data.admin_user.created_at);

            const categoryRequest = await fetch(`${API_URL}/admin/dashboard/productcategories`, {
                method: "GET",
                credentials: "include"
            });
            const categoryData = await categoryRequest.json();
            if(!categoryData.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(categoryData.response);
                setLoaded(true);
                return;
            };
            setProductCategories(categoryData.product_categories.map(p => {
                return { category_id: p.category_id, name: p.name };
            }));

            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Admin User {username}</div>
                    <div className="user-home-welcome-text">Create, view and manage admin user {username} ({getUserId()})</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <AuditLogTable events={auditEvents} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <EditAdminUser user_id={getUserId()} product_categories={productCategories} username={username} user_type={adminUserType} accessible_category_ids={accessibleCategories} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdminUser;