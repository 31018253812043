import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

import { API_URL } from "../../../config";

const PaymentMethod = ({ id, image, name, send_based, send_value_title, send_content, apikey_based, apikey_content, webhook_based, webhook_content, enabled }) => {
    const [imageUrl, setImageUrl] = useState(image);
    const [sendContent, setSendContent] = useState(send_content);
    const [apikeyContent, setApikeyContent] = useState(apikey_content);
    const [enabledState, setEnabledState] = useState(enabled);
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const submit = async () => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/paymentmethods/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    send_content: sendContent,
                    apikey_content: apikeyContent,
                    enabled: enabledState,
                    image: imageUrl
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setLoaded(true);
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="payment-method">
            <DashboardLoading fullPage={false} />
        </div>
    );

    if(send_based === true) {
        return (
            <div className="payment-method">
                {error !== null ? <div className="auth-form-error">{error}</div> : null}

                <div className="payment-method-title">{name}</div>

                <div className="input-group">
                    <label>Image URL</label>
                    <input type="text" className="input" placeholder="https://i.imgur.com/xyz.png" defaultValue={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                </div>

                <div className="input-group">
                    <label>{send_value_title}</label>
                    <input type="text" className="input" placeholder="" defaultValue={sendContent} onChange={(e) => setSendContent(e.target.value)} />
                </div>

                <div className="input-group input-group-checkbox">
                    <label>Enabled</label>
                    <input type="checkbox" defaultChecked={enabledState} onChange={(e) => setEnabledState(e.target.checked)} />
                </div>

                <button className="btn btn-snb" onClick={() => submit()}>Save</button>
            </div>
        );
    } else if(apikey_based === true) {
        return (
            <div className="payment-method">
                {error !== null ? <div className="auth-form-error">{error}</div> : null}

                <div className="payment-method-title">{name}</div>

                <div className="input-group">
                    <label>Image URL</label>
                    <input type="text" className="input" placeholder="https://i.imgur.com/xyz.png" defaultValue={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                </div>

                <div className="input-group">
                    <label>API Key</label>
                    <input type="text" className="input" placeholder="" defaultValue={apikeyContent} onChange={(e) => setApikeyContent(e.target.value)} />
                </div>

                <div className="input-group input-group-checkbox">
                    <label>Enabled</label>
                    <input type="checkbox" defaultChecked={enabledState} onChange={(e) => setEnabledState(e.target.checked)} />
                </div>

                <button className="btn btn-snb" onClick={() => submit()}>Save</button>
            </div>
        );
    };
};

const PaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const request = await fetch(`${API_URL}/admin/dashboard/paymentmethods`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await request.json();
                if(!data.success) {
                    if(request.status === 403) return navigate("/auth/login");
                    setError(data.response || data.message);
                    setLoaded(true);
                    return;
                };
                setPaymentMethods(data.payment_methods);
                setLoaded(true);
            } catch(e) {
                setError("An unknown error occurred, please try again later!");
            }
        };
        fetchData();
    }, []);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-payment-methods">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Payment Methods</div>
                    <div className="user-home-welcome-text">View and manage available payment methods</div>
                </div>

                <div className="dashboard-payment-methods-content">
                    {paymentMethods.map((paymentMethod) => (
                        <PaymentMethod
                            id={paymentMethod.payment_method_id}
                            image={paymentMethod.image}
                            name={paymentMethod.name}
                            send_based={paymentMethod.send_based}
                            send_value_title={paymentMethod.send_value_title}
                            send_content={paymentMethod.send_content}
                            apikey_based={paymentMethod.apikey_based}
                            apikey_content={paymentMethod.apikey_content}
                            webhook_based={paymentMethod.webhook_based}
                            webhook_content={paymentMethod.webhook_content}
                            enabled={paymentMethod.enabled}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
};

export default PaymentMethods;