import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const CouponsTable = ({ coupons, setRefresh }) => {
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const navigate = useNavigate();

    const deleteCoupon = async(coupon_id) => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/coupons/${coupon_id}`, {
                method: "DELETE",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };

            setLoaded(true);
            setRefresh(Math.random().toString());
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    return (
        <>
            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <table className="userorders-table">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Discount</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {coupons.map(coupon => (
                        <tr key={`coupons-table-row-${coupon.coupon_id}`}>
                            <td>{coupon.code}</td>
                            <td>{coupon.discount}</td>
                            <td>{new Date(coupon.created_at).toLocaleString()}</td>
                            <td><button disabled={!loaded} className="btn btn-nrm btn-nrm-sm" onClick={() => deleteCoupon(coupon.coupon_id)}>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const CreateCoupon = ({ setRefresh }) => {
    const [couponCode, setCouponCode] = useState("");
    const [couponDiscount, setCouponDiscount] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/coupons`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    code: couponCode,
                    discount: couponDiscount
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };

            setLoaded(true);
            setRefresh(Math.random().toString());
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Create coupon</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Coupon Code</label>
                <input type="text" className="input" defaultValue={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Discount</label>
                <input type="number" className="input" placeholder="0.05" defaultValue={couponDiscount} onChange={(e) => setCouponDiscount(parseFloat(e.target.value))} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Create</button>
        </div>
    )
};

const Coupons = () => {
    const [coupons, setCoupons] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/coupons`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setCoupons(data.coupons);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Coupons</div>
                    <div className="user-home-welcome-text">View and manage coupons</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <CouponsTable coupons={coupons} setRefresh={setRefresh} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <CreateCoupon setRefresh={setRefresh} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Coupons;