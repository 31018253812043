import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faWallet, faBoxesStacked, faBars, faEnvelope, faUsers, faPercent, faTags, faRightFromBracket, faUserGear, faBullhorn, faChartLine } from "@fortawesome/free-solid-svg-icons";

import UserContext from "../../../contexts/user";

const SidebarOption = ({ icon, name, to }) => {
    return (
        <NavLink className={({ isActive }) => `admin-sidebar-option ${isActive ? "admin-sidebar-option-active" : null}`} to={to}>
            <FontAwesomeIcon icon={icon} />
            <div className="admin-sidebar-option-text">{name}</div>
        </NavLink>
    )
};

const Sidebar = () => {
    const { user } = useContext(UserContext);

    let sidebarOptions = <></>;
    if (user.user_type === "support") {
        sidebarOptions = <>
            <SidebarOption icon={faEnvelope} name="Tickets" to="/dashboard/tickets" />
        </>
    } else if (user.user_type === "stocker") {
        sidebarOptions = <>
            <SidebarOption icon={faBoxesStacked} name="Products" to="/dashboard/products/categories" />
        </>
    } else if (user.user_type === "supportstocker") {
        sidebarOptions = <>
            <SidebarOption icon={faBoxesStacked} name="Products" to="/dashboard/products/categories" />
            <SidebarOption icon={faEnvelope} name="Tickets" to="/dashboard/tickets" />
        </>
    } else if (user.user_type === "superuser") {
        sidebarOptions = <>
            <SidebarOption icon={faHouse} name="Home" to="/dashboard/home" />
            <SidebarOption icon={faBoxesStacked} name="Products" to="/dashboard/products/categories" />
            <SidebarOption icon={faPercent} name="Coupons" to="/dashboard/coupons" />
            <SidebarOption icon={faTags} name="Tags" to="/dashboard/tags" />
            <SidebarOption icon={faBars} name="Orders" to="/dashboard/orders" />
            <SidebarOption icon={faBullhorn} name="Announcements" to="/dashboard/announcements" />
            <SidebarOption icon={faEnvelope} name="Tickets" to="/dashboard/tickets" />
            <SidebarOption icon={faUsers} name="Users" to="/dashboard/users" />
            <SidebarOption icon={faWallet} name="Payment Methods" to="/dashboard/paymentmethods" />
            <SidebarOption icon={faUserGear} name="Admin Users" to="/dashboard/adminusers" />
        </>
    }

    return (
        <div className="admin-dashboard-sidebar">
            <div className="admin-dashboard-sidebar-branding">
                <div className="admin-dashboard-sidebar-branding-img">
                    <svg width="160" height="55" viewBox="0 0 160 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.489324" y="0.489324" width="54.0214" height="54.0214" rx="9.29715" fill="#1675FF" stroke="#002E94" stroke-width="0.978648" />
                        <path d="M14.6955 19.6329L9.854 13.2889C9.285 12.5433 9.81667 11.4688 10.7546 11.4688H46.7762C47.7961 11.4688 48.2966 12.7109 47.5617 13.418L40.9672 19.7621C40.7561 19.9651 40.4747 20.0785 40.1818 20.0785H15.5961C15.2428 20.0785 14.9098 19.9137 14.6955 19.6329Z" fill="white" />
                        <path d="M14.191 27.2448L15.8823 23.0532C16.0552 22.6248 16.4709 22.3442 16.9329 22.3442H37.252C38.273 22.3442 38.773 23.5887 38.0358 24.2951L18.2903 43.2143C17.3553 44.1103 15.8914 42.9895 16.5125 41.8531L22.7293 30.4777C23.1419 29.7228 22.5955 28.8015 21.7352 28.8015H15.2416C14.4386 28.8015 13.8905 27.9894 14.191 27.2448Z" fill="white" />
                        <path d="M69.1577 15.4082H87.4999L85.9933 20.9764H74.0381L72.9899 24.8741H83.7987L82.3903 30.1475H71.5815L69.387 38.336H63L69.1577 15.4082Z" fill="white" />
                        <path d="M89.545 15.4082H95.932L91.2809 32.7678H102.221L100.714 38.336H83.3872L89.545 15.4082Z" fill="white" />
                        <path d="M112.993 38.7618C110.024 38.7618 107.731 38.063 106.115 36.6655C104.521 35.2462 103.724 33.27 103.724 30.737C103.724 30.1693 103.768 29.5797 103.855 28.9683C103.942 28.3351 104.073 27.7018 104.248 27.0686L107.392 15.4082H113.812L110.57 27.5272C110.46 27.8984 110.373 28.2587 110.308 28.608C110.264 28.9574 110.242 29.3177 110.242 29.6889C110.242 30.737 110.548 31.5559 111.159 32.1455C111.771 32.735 112.655 33.0298 113.812 33.0298C115.057 33.0298 116.083 32.6259 116.891 31.8179C117.328 31.403 117.699 30.9008 118.005 30.3112C118.31 29.6998 118.583 28.9356 118.824 28.0185L122.23 15.4082H128.65L125.014 28.9683C124.206 31.9599 123.016 34.2417 121.444 35.8139C120.418 36.8402 119.195 37.5935 117.775 38.0739C116.356 38.5325 114.762 38.7618 112.993 38.7618Z" fill="white" />
                        <path d="M131.626 15.4082H137.98L131.822 38.336H125.468L131.626 15.4082Z" fill="white" />
                        <path d="M144.966 32.7023C146.298 32.7023 147.532 32.5058 148.667 32.1127C149.803 31.7197 150.752 31.141 151.517 30.3767C152.128 29.7653 152.609 29.0448 152.958 28.215C153.307 27.3852 153.482 26.5118 153.482 25.5947C153.482 24.8959 153.384 24.2954 153.187 23.7932C152.991 23.2691 152.707 22.8215 152.336 22.4503C151.899 22.0136 151.32 21.6751 150.6 21.4349C149.879 21.1729 148.962 21.0419 147.848 21.0419H146.014L142.87 32.7023H144.966ZM141.134 15.4082H148.536C150.763 15.4082 152.598 15.6812 154.039 16.2271C155.48 16.773 156.637 17.4826 157.511 18.3561C159.17 20.0156 160 22.2538 160 25.0706C160 26.7957 159.683 28.4224 159.05 29.9509C158.417 31.4795 157.478 32.866 156.233 34.1107C154.858 35.4864 153.176 36.5345 151.189 37.2551C149.202 37.9757 146.877 38.336 144.213 38.336H134.976L141.134 15.4082Z" fill="white" />
                    </svg>
                </div>
            </div>
            <div className="admin-dashboard-sidebar-options">
                {sidebarOptions}
                <SidebarOption icon={faRightFromBracket} name="Logout" to="/auth/logout" />
            </div>
        </div>
    );
};

export default Sidebar;