import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, STORE_NAME } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const reasonTitle = {
    "account": `A problem with my ${STORE_NAME} account`,
    "account_balance": `A problem with my ${STORE_NAME} account balance`,
    "order": "A problem with an order",
    "other": "Something else"
};

const TicketsTable = ({ tickets }) => {
    const navigate = useNavigate();

    return (
        <>
            <table className="userorders-table">
                <thead>
                    <tr>
                        <th>Reason</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map((ticket, index) => (
                        <tr>
                            <td>{reasonTitle[ticket.reason]}</td>
                            <td><div className={`userorders-table-status userorders-table-status-${ticket.status.toLowerCase()}`}>{ticket.status.replaceAll("_", " ")}</div></td>
                            <td>{new Date(ticket.created_at).toLocaleString()}</td>
                            <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/tickets/${ticket.ticket_id}`)}>View</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const TicketsSearch = () => {
    const [ticketId, setTicketId] = useState(null);
    const navigate = useNavigate();

    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Search tickets</div>
            </div>

            <div className="input-group">
                <label>Ticket ID</label>
                <input type="text" className="input" defaultValue={ticketId} onChange={(e) => setTicketId(e.target.value)} />
            </div>

            <button className="btn btn-snb" onClick={() => navigate(`/dashboard/tickets/${ticketId}`)}>Search</button>
        </div>
    )
};

const Tickets = () => {
    const [tickets, setTickets] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tickets`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };
            setTickets(data.tickets);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Tickets</div>
                    <div className="user-home-welcome-text">View, manage and search tickets</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <TicketsTable tickets={tickets} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <TicketsSearch />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Tickets;