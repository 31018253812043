import { Oval } from "react-loader-spinner";

const Loading = ({ fullPage }) => {
    return (
        <div className={`dashboard-loading ${fullPage === false ? "" : "dashboard-loading-full-page"}`}>
            <Oval
                color="#3478fd"
                secondaryColor="#3478fd"
                width={65}
                height={65}
                strokeWidth={3.5}
                strokeWidthSecondary={3.5}
            />
        </div>
    )
};

export default Loading;