import { useState, useContext, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Turnstile } from "@marsidev/react-turnstile";

import UserContext from "../../../contexts/user";

import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY } from "../../../config";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const captchaRef = useRef();
    const navigate = useNavigate();
    
    const submit = async() => {
        try {
            const data = await fetch(`${API_URL}/admin/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username,
                    password,
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCaptcha(null);
                setError(data.response || data.message);
                captchaRef.current?.reset();
                return;
            };
            
            setUser({
                loggedin: true,
                username: data.user.username,
                user_type: data.user.user_type
            });
            Cookies.set("admin_loggedin", "true");
            Cookies.set("admin_username", data.user.username);
            Cookies.set("admin_usertype", data.user.user_type);
            if(data.user.user_type === "superuser") {
                navigate("/dashboard/home");
            } else if(data.user.user_type === "supportstocker" || data.user.user_type === "stocker") {
                navigate("/dashboard/products/categories");
            } else if(data.user.user_type === "support") {
                navigate("/dashboard/tickets");
            };
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    return (
        <div className="auth-form-page">
            <div className="auth-form">
                <div className="auth-form-title">Login</div>
                <div className="auth-form-text">Please enter your login credentials to continue</div>

                {error !== false ? <div className="auth-form-error">{error}</div> : null}

                <div className="auth-form-inputs">
                    <input className="auth-form-input" placeholder="Username" type="text" onChange={(e) => setUsername(e.target.value)} />
                    <input className="auth-form-input" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="auth-form-captcha">
                    <Turnstile siteKey={CLOUDFLARE_TURNSTILE_SITEKEY} ref={captchaRef} onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} />
                </div>

                <button className="btn auth-form-btn" disabled={captcha == null} onClick={() => submit()}>Login</button>
            </div>
        </div>
    );
};

export default Login;