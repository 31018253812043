import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faCircleCheck, faClock, faTruck, faFileInvoiceDollar, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

import { API_URL } from "../../../config";

const copyText = (text) => {
    var input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
};

const ItemContentValue = ({ values }) => {
    return (
        <div className="item-values-container">
            {values.map(({ title, value }, index) =>  {
                return (
                    <div className="item-value-container" key={`item-value-${title}-${index}`}>
                        <div className="item-value-title">{title}</div>
                        <div className="item-value-text">{value !== undefined ? value.toString() : "-"}</div>
                    </div>
                )
            })}
        </div>
    );
};

const OrderProductStock = ({ stock }) => {
    const [copyButtonTick, setCopyButtonTick] = useState(false);

    return (
        <div className="storefront-product-stock-container">
            <div className="storefront-cart-item-title dflx">
                <div className="storefront-cart-content-title">Stock</div>
                <button className="btn btn-nrm btn-nrm-sm btn-nrm-xsm" disabled={copyButtonTick} onClick={() => {
                    copyText(stock.join("\n"));
                    setCopyButtonTick(true);
                    setTimeout(() => setCopyButtonTick(false), 1000);
                }}>
                    {copyButtonTick 
                        ? <><FontAwesomeIcon icon={faCircleCheck} />Copied</>
                        : <><FontAwesomeIcon icon={faClipboard} />Copy</>
                    }
                </button>
            </div>
            <div className="userorder-content">
                {stock.map(s => <div key={`order-content-line-${Math.random.toString()}`}>{s}</div>)}
            </div>
        </div>
    )
};

const OrderProduct = ({ image, name, quantity, price_per_item, stock }) => {
    return (
        <div className="storefront-cart-item">
            <div className="storefront-cart-item-title">
                <div className="storefront-cart-content-title">{name}</div>
            </div>
            <div className="storefront-cart-item-content">
                <img className="storefront-cart-image" src={image} alt="Item icon" />
                <div className="storefront-cart-item-options">
                    <div className="storefront-cart-content-quantity">${price_per_item.toFixed(2)} x {quantity}</div>
                    <div className="storefront-cart-content-price">${(price_per_item * quantity).toFixed(2)}</div>
                </div>
            </div>
            {stock.length > 0 ? <OrderProductStock stock={stock} /> : null}
        </div>
    )
};

const OrderProducts = ({ products }) => {
    return (
        <div className="adminorder-data-products-list">
            {products.map((product, index) => {
                return <OrderProduct key={`order-item-${index}`} image={product.image} name={product.name} quantity={product.quantity} price_per_item={product.price_per_item} stock={product.stock} />;
            })}
        </div>
    )
};

const OrderTimelineEvent = ({ icon, title, text, timestamp }) => {
    return (
        <div className="order-timeline-event">
            <div className="order-timeline-event-icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="order-timeline-event-content">
                <div className="order-timeline-event-title">{title}</div>
                <div className="order-timeline-event-text">{text}</div>
                <div className="order-timeline-event-timestamp">{timestamp}</div>
            </div>
        </div>
    )
};

const OrderTimeline = ({ events }) => {
    return (
        <div className="order-timeline-events">
            {events.map((event, index) => (
                <OrderTimelineEvent icon={event.icon} title={event.title} text={event.text} timestamp={event.timestamp} />
            ))}
        </div>
    )
};

const Order = () => {
    const [orderTitle, setOrderTitle] = useState("");
    const [guestOrder, setGuestOrder] = useState(false);
    const [guestOrderEmail, setGuestOrderEmail] = useState("");
    const [total, setTotal] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [cancelledByProvider, setCancelledByProvider] = useState(false);
    const [stockDeliveryIssue, setStockDeliveryIssue] = useState(false);
    const [stockDeliveryIssueProducts, setStockDeliveryIssueProducts] = useState([]);
    const [confirmedAt, setConfirmedAt] = useState(null);
    const [deliveredAt, setDeliveredAt] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [events, setEvents] = useState([]);

    const [orderProducts, setOrderProducts] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const getOrderId = () => {
        let oId = window.location.pathname.split("/")[3];
        return oId.includes("/") ? oId.split("/")[0] : oId;
    };

    const fetchData = async() => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/orders/${getOrderId()}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response || data.message);
                setLoaded(true);
                return;
            };

            setOrderTitle(data.order.title);
            setGuestOrder(data.order.guest_order);
            setGuestOrderEmail(data.order.guest_order_email);
            setTotal(data.order.total);
            setPaymentMethod(data.order.payment_method);
            setOrderStatus(data.order.status);
            setCancelledByProvider(data.order.cancelled_by_provider);
            setStockDeliveryIssue(data.order.stock_delivery_issue);
            setStockDeliveryIssueProducts(data.order.stock_delivery_products);
            setConfirmedAt(data.order.confirmed_at);
            setDeliveredAt(data.order.delivered_at);
            setCreatedAt(data.order.created_at);
            setOrderProducts(data.order.products);

            let events = [{
                icon: faClock,
                title: "Order Created",
                text: `The order was created`,
                timestamp: new Date(data.order.created_at).toLocaleString()
            }];

            if(data.order.status === "cancelled") {
                events.push({
                    icon: faCircleXmark,
                    title: "Order Cancelled",
                    text: `The order was cancelled automatically after expiring`,
                    timestamp: new Date().toLocaleString()
                });
            } else if(data.order.status === "completed") {
                events.push({
                    icon: faFileInvoiceDollar,
                    title: "Payment Confirmed",
                    text: `A payment confirmation was received from the payment gateway`,
                    timestamp: new Date(data.order.confirmed_at).toLocaleString()
                });

                events.push({
                    icon: faTruck,
                    title: "Order Delivered",
                    text: `All products within the order were delivered succesfully`,
                    timestamp: new Date(data.order.delivered_at).toLocaleString()
                });

                events.push({
                    icon: faCircleCheck,
                    title: "Order Completed",
                    text: `The order was succesfully completed`,
                    timestamp: new Date(data.order.delivered_at).toLocaleString()
                });
            } else if(data.order.status === "awaiting_payment") {
                events.push({
                    icon: faTruck,
                    title: "Order Awaiting Payment",
                    text: `The order is currently awaiting payment`,
                    timestamp: new Date(data.order.created_at).toLocaleString()
                });
            } else if(data.order.status === "processing") {
                events.push({
                    icon: faFileInvoiceDollar,
                    title: "Payment Confirmed",
                    text: `A payment confirmation was received from the payment gateway`,
                    timestamp: new Date(data.order.confirmed_at).toLocaleString()
                });
            };

            setEvents(events);

            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Order {getOrderId()}</div>
                    <div className="user-home-welcome-text">View and manage order {getOrderId()}</div>
                </div>

                <div className="adminorder-data-title">Order Information</div>

                <div className="item-content-container">
                    <div className="item-content-row">
                        <ItemContentValue 
                            values={
                                [
                                    {
                                        title: "Title",
                                        value: orderTitle
                                    },
                                    {
                                        title: "Cancelled by Payment Provider",
                                        value: cancelledByProvider
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Status",
                                        value: orderStatus
                                    },
                                    {
                                        title: "Stock Delivery Issue",
                                        value: stockDeliveryIssue
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Total",
                                        value: `$${total.toFixed(2)}`
                                    },
                                    {
                                        title: "Account Balance Used",
                                        value: "Yes"
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Payment Method",
                                        value: paymentMethod
                                    },
                                    {
                                        title: "Delivered At",
                                        value: new Date(deliveredAt).toLocaleString()
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Guest Order",
                                        value: `${guestOrder} ${guestOrderEmail ? "(" + guestOrderEmail + ")" : ""}`
                                    },
                                    {
                                        title: "Created At",
                                        value: new Date(createdAt).toLocaleString()
                                    }
                                ]
                            }
                        />
                    </div>
                </div>

                {stockDeliveryIssue
                ? <div className="alert alert-wrn mt-4">
                    Some products in this order were out of stock, and require manual fuffilment
                    <ul>
                        {stockDeliveryIssueProducts.map((product, index) => (
                            <li key={`prod-${Math.random().toString()}`}>x{product.quantity} {product.name}</li>
                        ))}
                    </ul>
                    </div>
                : null}

                <div className="adminorder-data">
                    <div className="adminorder-data-products">
                        <div className="adminorder-data-title">Order Products</div>
                        <OrderProducts products={orderProducts} />
                    </div>
                    <div className="adminorder-data-timeline">
                        <div className="adminorder-data-title">Order Timeline</div>
                        <OrderTimeline
                            events={events}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order;